var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"custom-scroll overflow-auto",staticStyle:{"height":"calc(100vh - 110px)"}},[_c('div',{staticClass:"row mx-0 align-items-center"},[_c('div',{staticClass:"btn-back",on:{"click":function($event){return _vm.$router.push({name:'admin.promociones.grupos.ver'})}}},[_c('i',{staticClass:"icon-left f-12"})]),_c('div',{staticClass:"col-auto text-general f-18 px-2"},[_vm._v(" Crear Promocion ")])]),_c('ValidationObserver',{ref:"form"},[_c('div',{staticClass:"row mx-0 mt-3 bg-white br-8 px-4 py-4"},[_c('p',{staticClass:"col-12 text-general f-18 mb-4"},[_vm._v(" Datos generales ")]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-4 pl-0"},[_c('div',{staticClass:"row w-100 mb-3"},[_c('p',{staticClass:"text-general pl-3 f-12"},[_vm._v(" Nombre de la promoción ")]),_c('ValidationProvider',{staticClass:"w-100",attrs:{"rules":"required|max:40","name":"nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Nombre","size":"small"},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}}),_c('span',{staticClass:"text-danger f-10"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"row w-100"},[_c('p',{staticClass:"text-general pl-3 f-12"},[_vm._v(" Condiciones ")]),_c('ValidationProvider',{staticClass:"w-100",attrs:{"rules":"required|max:500","name":"condiciones"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"type":"textarea","rows":4,"size":"small","placeholder":"Condiciones"},model:{value:(_vm.condiciones),callback:function ($$v) {_vm.condiciones=$$v},expression:"condiciones"}}),_c('span',{staticClass:"text-danger f-10"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"row my-3 mx-sm-n4"},[_c('div',{staticClass:"col-6"},[_c('p',{staticClass:"text-general f-12"},[_vm._v(" Hora inicio ")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Hora inicio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-time-select',{staticClass:"w-100",attrs:{"size":"small","placeholder":"Hora inicio","picker-options":{
                                        start: '00:00',
                                        step: '00:30',
                                        end: '23:30'
                                    }},model:{value:(_vm.horaInicio),callback:function ($$v) {_vm.horaInicio=$$v},expression:"horaInicio"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-6"},[_c('p',{staticClass:"text-general f-12"},[_vm._v(" Hora fin ")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Hora fin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('el-time-select',{staticClass:"w-100",attrs:{"size":"small","placeholder":"Hora fin","picker-options":{
                                        start: '00:00',
                                        step: '00:30',
                                        end: '23:30',
                                        minTime: _vm.horaInicio
                                    }},model:{value:(_vm.horaFin),callback:function ($$v) {_vm.horaFin=$$v},expression:"horaFin"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('el-checkbox',{attrs:{"true-label":1,"false-label":2},model:{value:(_vm.acumulable),callback:function ($$v) {_vm.acumulable=$$v},expression:"acumulable"}},[_vm._v(" Acumulable con otras promociones. ")])],1)]),_c('div',{staticClass:"col-4 py-2"},[_c('slim-cropper',{ref:"fotoPromocion",staticClass:"border cr-pointer",staticStyle:{"background-color":"#F5F5F5","border-radius":"12px"},attrs:{"options":_vm.slimOptions}}),_c('p',{staticClass:"text-grey text-right f-12"},[_vm._v(" Relación Aspecto 6:2, Min: 600px Ancho x 200px Alto ")])],1)])])])]),_c('div',{staticClass:"row mx-0 mt-3 mb-4"},[_c('div',{staticClass:"col-auto px-4"}),_c('div',{staticClass:"col-4"},[_c('small',{staticClass:"pl-3 text-general"},[_vm._v(" Tipo de promoción ")]),_c('el-select',{staticClass:"w-100",attrs:{"size":"small"},on:{"change":_vm.guardarPromocion},model:{value:(_vm.tipoPromocion),callback:function ($$v) {_vm.tipoPromocion=$$v},expression:"tipoPromocion"}},_vm._l((_vm.tiposPromociones),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
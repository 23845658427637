<template>
    <section class="custom-scroll overflow-auto" style="height:calc(100vh - 110px)">
        <div class="row mx-0 align-items-center">
            <div class="btn-back" @click="$router.push({name:'admin.promociones.grupos.ver'})">
                <i class="icon-left f-12" />
            </div>
            <div class="col-auto text-general f-18 px-2">
                Crear Promocion
            </div>
        </div>
        <ValidationObserver ref="form">
            <div class="row mx-0 mt-3 bg-white br-8 px-4 py-4">
                <p class="col-12 text-general f-18 mb-4">
                    Datos generales
                </p>
                <div class="col-12">
                    <div class="row mx-0">
                        <div class="col-4 pl-0">
                            <div class="row w-100 mb-3">
                                <p class="text-general pl-3 f-12">
                                    Nombre de la promoción
                                </p>
                                <ValidationProvider v-slot="{errors}" class="w-100" rules="required|max:40" name="nombre">
                                    <el-input v-model="nombre" placeholder="Nombre" class="w-100" size="small" />
                                    <span class="text-danger f-10">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="row w-100">
                                <p class="text-general pl-3 f-12">
                                    Condiciones
                                </p>
                                <ValidationProvider v-slot="{errors}" class="w-100" rules="required|max:500" name="condiciones">
                                    <el-input v-model="condiciones" type="textarea" :rows="4" size="small" placeholder="Condiciones" class="w-100" />
                                    <span class="text-danger f-10">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="row my-3 mx-sm-n4">
                                <div class="col-6">
                                    <p class="text-general f-12">
                                        Hora inicio
                                    </p>
                                    <ValidationProvider v-slot="{errors}" rules="required" name="Hora inicio">
                                        <el-time-select
                                        v-model="horaInicio"
                                        class="w-100"
                                        size="small"
                                        placeholder="Hora inicio"
                                        :picker-options="{
                                            start: '00:00',
                                            step: '00:30',
                                            end: '23:30'
                                        }"
                                        />
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-6">
                                    <p class="text-general f-12">
                                        Hora fin
                                    </p>
                                    <ValidationProvider v-slot="{errors}" rules="required" name="Hora fin">
                                        <el-time-select
                                        v-model="horaFin"
                                        class="w-100"
                                        size="small"
                                        placeholder="Hora fin"
                                        :picker-options="{
                                            start: '00:00',
                                            step: '00:30',
                                            end: '23:30',
                                            minTime: horaInicio
                                        }"
                                        />
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <el-checkbox v-model="acumulable" :true-label="1" :false-label="2">
                                    Acumulable con otras promociones.
                                </el-checkbox>
                            </div>
                        </div>
                        <div class="col-4 py-2">
                            <slim-cropper ref="fotoPromocion" :options="slimOptions" class="border cr-pointer" style="background-color:#F5F5F5;border-radius:12px;" />
                            <p class="text-grey text-right f-12">
                                Relación Aspecto 6:2, Min: 600px Ancho x 200px Alto
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
        <div class="row mx-0 mt-3 mb-4">
            <div class="col-auto px-4" />
            <div class="col-4">
                <small class="pl-3 text-general">
                    Tipo de promoción
                </small>
                <el-select v-model="tipoPromocion" size="small" class="w-100" @change="guardarPromocion">
                    <el-option
                    v-for="item in tiposPromociones"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </div>
        </div>
    </section>
</template>

<script>
import Promociones from "~/services/promociones/promociones_grupo";
export default {
    data(){
        return {
            loading: false,
            nombre: '',
            condiciones: '',
            slimOptions: {
                ratio: '6:2',
                label:'Subir imagen',
                minSize:'600,200',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'Ok',
                buttonUploadLabel: 'Ok',
            },
            tiposPromociones: [{
                value: 2,
                label: 'Rebajado (Valor)'
            }, {
                value: 3,
                label: 'Descuento fijo (Porcentaje)'
            }, {
                value: 4,
                label: 'Descuento variable (Porcentaje)'
            }, {
                value: 5,
                label: 'Destacado'
            }],
            tipoPromocion: '',
            idPromocion: null,
            horaInicio: '00:01',
            horaFin: '23:59',
            acumulable: 2
        }
    },
    computed: {
        idGrupo(){
            return this.$route.params.idGrupo;
        },
    },
    methods: {
        async guardarPromocion(){
            try {
                let valid = await this.$refs.form.validate()
                if(!valid){
                    this.tipoPromocion = ''
                    return this.notificacion('','Campos incompletos','warning')
                }

                this.loading = true
                const archivo = this.$refs.fotoPromocion.instanciaCrop.dataBase64.output.image

                const payload = {
                    nombre : this.nombre,
                    condiciones: this.condiciones,
                    imagen: archivo? archivo : null,
                    tipo: this.tipoPromocion,
                    idCarpeta: this.idGrupo,
                    acumulable: this.acumulable,
                    horaInicio: this.horaInicio,
                    horaFin: this.horaFin,
                }

                const {data} = await Promociones.guardarPromocion(payload)
                this.idPromocion = data.data.id
                this.notificacion('Mensaje', 'Guardado exitoso', 'success')
                this.loading = false
                this.$router.push({name: "admin.promociones.editar", params:{idGrupo: this.idGrupo, idPromocion:this.idPromocion}})
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.container-dia{
    width: 50px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #DFE4E8;
    background-color: #F6F9FB;
    color: #637381;
    display: flex;
    align-items: center;
    justify-content: center;
    &-activo{
        @extend .container-dia;
        background-color: #000000 !important;
        color: #FFFFFF !important;
    }
}
</style>
